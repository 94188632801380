<template>
  <div v-if="dialog" class="relative z-50">
    <div class="fixed inset-0 bg-black bg-opacity-80"></div>
    <div class="fixed inset-0 overflow-auto">
      <div class="flex items-center justify-center h-full">
        <div
          class="bg-white rounded-lg p-4 w-full max-w-sm md:max-w-md lg:max-w-md md:mx-0 mx-4"
        >
          <div>
            <div class="font-bold mt-2">
              <div class="flex flex-col gap-2">
                <div
                  v-for="(row, idx) in listToko"
                  :key="idx"
                  class="flex flex-row gap-4 mx-2 outline-1 outline-gray-400 rounded-md p-1 cursor-pointer hover:bg-gray-400"
                  @click="confirm(row)"
                >
                  <div class="flex flex-row justify-center">
                    <div class="grid items-center">{{ row.nama }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      listToko: [],
      mapCurrency: {
        ID: "IDR",
        MY: "MYR",
      },
    };
  },

  methods: {
    open({ listToko }) {
      this.listToko = listToko;
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    confirm(toko) {
      this.resolvePromise(toko);
      this.dialog = false;
    },
    cancel() {
      this.resolvePromise(null);
      this.dialog = false;
    },
  },
};
</script>
